import { getSize } from '../iconUtils'
import * as React from 'react'
import type { SVGProps } from 'react'
export interface BrandIconRoadsTollReliefCTPProps {
    title?: string;
    titleId?: string;
    size?: 'lg' | 'xl' | 'xxl' | string | number;
}
export const BrandIconRoadsTollReliefCTP = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & BrandIconRoadsTollReliefCTPProps) => {
    const displaySize = getSize(props.size ?? 'xl')
    const displayColor = undefined
    props.focusable = props.focusable ?? false
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={displayColor}
            aria-hidden={!title}
            role={title ? 'img' : undefined}
            width={displaySize}
            height={displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.RoadsTollReliefCTP_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='RoadsTollReliefCTP_svg__cls-1'
                d='M0 15h48v2H0zm-.005 11.966l.002-2 26 .032-.002 2z'
            />
            <path
                className='RoadsTollReliefCTP_svg__cls-1'
                d='M26.2 47.9a18.55 18.55 0 01-10.38-3.17 15.81 15.81 0 01-6.2-8.88A27.6 27.6 0 019 29h2a26.88 26.88 0 00.53 6.27 13.93 13.93 0 005.41 7.8 16.53 16.53 0 009.26 2.83c8.12 0 13.8-5.26 13.8-12.8a36.32 36.32 0 00-1-6.86A1 1 0 0140 25h8v2h-6.75a33.85 33.85 0 01.75 6.1c0 8.71-6.5 14.8-15.8 14.8zM9 0h2v13H9zm10 0h2v13h-2z'
            />
            <path
                className='RoadsTollReliefCTP_svg__cls-1'
                d='M16.15 34a1 1 0 01-1-.89A30.21 30.21 0 0115 30a1 1 0 012 0c0 .37 0 1.66.14 2.89a1 1 0 01-.88 1.1zM24 41.82h-.16a14.48 14.48 0 01-5.35-1.88l-.35-.24a1 1 0 01-.21-1.4 1 1 0 011.4-.21l.28.19a12 12 0 004.55 1.57 1 1 0 01-.16 2zm6.49-.65a1 1 0 01-.49-1.91 6.08 6.08 0 003.31-3.42 1 1 0 111.85.75 8 8 0 01-4.35 4.5 1 1 0 01-.37.08zm4.45-10.46a1 1 0 01-1-.9A10.15 10.15 0 0032.26 25a1 1 0 01.26-1.39 1 1 0 011.39.26 12.11 12.11 0 012 5.76A1 1 0 0135 30.7zm-7.33-8.47h-.15A22.86 22.86 0 0024 22h-2.09a1 1 0 110-2H24a24.24 24.24 0 013.71.25 1 1 0 01-.15 2zM15.31 22H9.64a1 1 0 01-1-1 1 1 0 011-1h5.66a1 1 0 010 2zM3 22H0v-2h3a1 1 0 010 2zm42 0h-6a1 1 0 010-2h6a1 1 0 010 2zM15 10a1 1 0 01-1-1V4.13a1 1 0 012 0V9a1 1 0 01-1 1z'
            />
            <circle cx={26} cy={30} r={5} fill='#d7153a' />
        </svg>
    )
}
