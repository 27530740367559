import styled from 'styled-components'
import { baseInputStyles, inputWidthVar } from '@snsw-gel/field'
import { pxToRem, getSpacing } from '@snsw-gel/utils'
import { vars } from '@snsw-gel/theming'

export const flags = {
    hasValue: 'has-value',
    isSmall: 'is-small',
}

export const StyledSelect = styled.select``

export const SelectWrapper = styled.div`
    position: relative;
    max-width: ${inputWidthVar};
    color: ${vars.colors.forms.helpText};
    margin-top: ${pxToRem(4)};

    select {
        ${baseInputStyles}
        width: 100%;
        max-width: 100%;
        color: inherit;
        margin-top: 0;
        cursor: pointer;
        padding-right: ${getSpacing('xl')};

        &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 ${vars.colors.forms.text};
        }
        &::-ms-expand {
            display: none;
        }

        option:not(:checked) {
            color: ${vars.colors.forms.text};
        }
    }

    & > select:has(option:not([value='']):checked) {
        color: ${vars.colors.forms.text};
    }

    &.${flags.isSmall} {
        max-width: ${pxToRem(174)};
    }

    &.${flags.hasValue} select {
        color: ${vars.colors.forms.text};
    }

    svg {
        color: ${vars.colors.forms.text};
    }

    svg {
        position: absolute;
        top: 50%;
        right: ${pxToRem(16)};
        pointer-events: none;
        transform: translateY(-50%);
        width: ${pxToRem(16)};
        height: ${pxToRem(16)};
    }
`
