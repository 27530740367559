import styled, { css } from 'styled-components'
import { getBreakpoint, pxToRem } from '@snsw-gel/utils'
import { tokens } from '@snsw-gel/tokens'
import PropTypes from 'prop-types'

const { columns, gutters } = tokens.layouts.grid

const halfGutter = pxToRem(gutters / 2)

const columnWidth = props => `${(props.span / columns) * 100}%`

const Col = styled.div`
    padding-left: 0;
    padding-right: 0;

    ${getBreakpoint.md`
        padding-left: ${halfGutter};
        padding-right: ${halfGutter};
        flex-basis: 0;
        flex-grow: 1;

        ${props =>
            props.span &&
            css`
                max-width: ${columnWidth};
            `}
    `}
`

Col.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node,
    span: PropTypes.oneOf([3, 4, 6, 8, 9, 12]).isRequired,
}

export default Col
