import { getSize } from '../iconUtils'
import * as React from 'react'
import type { SVGProps } from 'react'
export interface BrandIconRoadFreightProps {
    title?: string;
    titleId?: string;
    size?: 'lg' | 'xl' | 'xxl' | string | number;
}
export const BrandIconRoadFreight = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & BrandIconRoadFreightProps) => {
    const displaySize = getSize(props.size ?? 'xl')
    const displayColor = undefined
    props.focusable = props.focusable ?? false
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={displayColor}
            aria-hidden={!title}
            role={title ? 'img' : undefined}
            width={displaySize}
            height={displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M16 25a5 5 0 115-5 5 5 0 01-5 5z' fill='#d7153a' />
            <path
                d='M47.76 19.35l-6-7A1 1 0 0041 12h-8a1 1 0 00-1 1v17h-2V5a1 1 0 00-1-1H3a1 1 0 00-1 1v25H1a1 1 0 00-1 1v9a1 1 0 001 1h2.1a5 5 0 009.8 0h3.2a5 5 0 009.8 0h7.2a5 5 0 009.8 0H47a1 1 0 001-1V20a1 1 0 00-.24-.65zM14 6h4v4h-4zM4 6h8v5a1 1 0 001 1h6a1 1 0 001-1V6h8v24H4zm4 37a3 3 0 113-3 3 3 0 01-3 3zm13 0a3 3 0 113-3 3 3 0 01-3 3zm17 0a3 3 0 113-3 3 3 0 01-3 3zm8-17h-8v-7h-2v8a1 1 0 001 1h9v11h-3.1a5 5 0 00-9.8 0h-7.2a5 5 0 00-9.8 0h-3.2a5 5 0 00-9.8 0H2v-7h31a1 1 0 001-1V14h6.54L46 20.37z'
                fill='#002664'
            />
        </svg>
    )
}
