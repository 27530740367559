import { getSize } from '../iconUtils'
import * as React from 'react'
import type { SVGProps } from 'react'
export interface BrandIconCommunityProps {
    title?: string;
    titleId?: string;
    size?: 'lg' | 'xl' | 'xxl' | string | number;
}
export const BrandIconCommunity = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & BrandIconCommunityProps) => {
    const displaySize = getSize(props.size ?? 'xl')
    const displayColor = undefined
    props.focusable = props.focusable ?? false
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={displayColor}
            aria-hidden={!title}
            role={title ? 'img' : undefined}
            width={displaySize}
            height={displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Community_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='Community_svg__cls-1'
                d='M35.09 18.9a1.09 1.09 0 01-.39-.08 1 1 0 01-.53-1.31l.75-1.8a1 1 0 111.85.77l-.77 1.8a1 1 0 01-.91.62zm6.17-15.1a.91.91 0 01-.39-.08 1 1 0 01-.53-1.3l.75-1.81A1 1 0 0142.4.08a1 1 0 01.54 1.31l-.76 1.8a1 1 0 01-.92.61zM30.13 14a1 1 0 01-.38-1.92l1.81-.74a1 1 0 01.75 1.85l-1.8.74a1 1 0 01-.38.07zm15.04-6.34a1 1 0 01-.38-1.92l1.8-.74a1 1 0 11.76 1.85l-1.81.74a1 1 0 01-.37.07zm-13.27.08a1 1 0 01-.38-.08l-1.8-.75a1 1 0 11.77-1.85l1.8.76a1 1 0 01-.39 1.92zM47 13.91a.91.91 0 01-.39-.08l-1.8-.75a1 1 0 01.78-1.85l1.8.76a1 1 0 01-.39 1.92zm-4.92 4.96a1 1 0 01-.93-.62l-.74-1.81a1 1 0 011.85-.75l.74 1.8a1 1 0 01-.54 1.31 1.06 1.06 0 01-.38.07zM35.77 3.83a1 1 0 01-.93-.62l-.74-1.8A1 1 0 1136 .65l.74 1.81a1 1 0 01-.54 1.3 1.06 1.06 0 01-.43.07z'
            />
            <path d='M44 9.45a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
            <path
                className='Community_svg__cls-1'
                d='M47.31 46A83.57 83.57 0 0034 43.2v-3.83a8.17 8.17 0 003.3.72c5 0 8.07-2.52 8.07-6.58 0-3.22-1.35-5.46-4-6.69a9 9 0 00-8.46-6.51 7.83 7.83 0 00-4.78 1.43v.51c.1.59.17 1.17.22 1.74l.29-.19a6.49 6.49 0 014.27-1.49 6.94 6.94 0 016.62 5.43 1 1 0 00.62.74c2.25.87 3.25 2.42 3.25 5 0 4.13-4.24 4.58-6.07 4.58a6.09 6.09 0 01-3.3-1v-4.29l3.45-1.72a1 1 0 00.44-1.34 1 1 0 00-1.34-.45L34 30.53V27.6a1 1 0 00-2 0v5.94l-2.55-1.28a1 1 0 10-.9 1.79L32 35.78v1a6 6 0 01-3.78 1.34 5.72 5.72 0 01-4.47-2.36c-.11.11-.23.21-.34.3a12 12 0 01-1.25.9 7.69 7.69 0 006.06 3.16 8 8 0 003.78-1V43a74.29 74.29 0 00-8-.48 76 76 0 00-8 .48v-6.1a11.76 11.76 0 007.76-3.81 11.18 11.18 0 002.57-7.55 17.21 17.21 0 00-.33-3.06c-.34-2-3.57-19.18-11.64-19.18C7.3 3.3 3.5 17.24 2.63 22.61a15.82 15.82 0 00-.2 2.52A11.45 11.45 0 005 32.64 12.51 12.51 0 0014 37v6.26A83.05 83.05 0 00.69 46.05 1 1 0 001 48a1.12 1.12 0 00.31 0A84.16 84.16 0 0124 44.52a84.43 84.43 0 0122.67 3.4 1 1 0 10.62-1.9zM14 20.15l-2.4-1.8a1 1 0 10-1.2 1.6l3.6 2.7V27l-4.39-3.61a1 1 0 10-1.28 1.53l5.67 4.7V35a10.46 10.46 0 01-7.42-3.59 9.53 9.53 0 01-2.12-6.25 13.83 13.83 0 01.18-2.18C5.76 15.82 9.66 5.3 14.38 5.3s8.45 10.48 9.67 17.53a15.16 15.16 0 01.25 2.7 9.32 9.32 0 01-2.08 6.25A9.7 9.7 0 0116 34.89v-5.27l5.64-4.7a1 1 0 10-1.28-1.53L16 27v-4.35l3.6-2.7a1 1 0 00-1.2-1.6l-2.4 1.8v-5a1 1 0 00-2 0z'
            />
        </svg>
    )
}
