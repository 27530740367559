import { getSize } from '../iconUtils'
import * as React from 'react'
import type { SVGProps } from 'react'
export interface BrandIconMobileProps {
    title?: string;
    titleId?: string;
    size?: 'lg' | 'xl' | 'xxl' | string | number;
}
export const BrandIconMobile = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & BrandIconMobileProps) => {
    const displaySize = getSize(props.size ?? 'xl')
    const displayColor = undefined
    props.focusable = props.focusable ?? false
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={displayColor}
            aria-hidden={!title}
            role={title ? 'img' : undefined}
            width={displaySize}
            height={displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Mobile_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='Mobile_svg__cls-1'
                d='M35 40h-4v-2h3V6H14v32h3v2h-4a1 1 0 01-1-1V5a1 1 0 011-1h22a1 1 0 011 1v34a1 1 0 01-1 1z'
            />
            <path d='M24 44a5 5 0 115-5 5 5 0 01-5 5z' fill='#d7153a' />
            <path
                className='Mobile_svg__cls-1'
                d='M35 48H13a5 5 0 01-5-5V5a5 5 0 015-5h22a5 5 0 015 4.87V43a5 5 0 01-5 5zM13 2a3 3 0 00-3 3v38a3 3 0 003 3h22a3 3 0 003-3V5a3 3 0 00-3-3z'
            />
        </svg>
    )
}
