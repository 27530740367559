import { getSize } from '../iconUtils'
import * as React from 'react'
import type { SVGProps } from 'react'
export interface BrandIconMotorbikeProps {
    title?: string;
    titleId?: string;
    size?: 'lg' | 'xl' | 'xxl' | string | number;
}
export const BrandIconMotorbike = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & BrandIconMotorbikeProps) => {
    const displaySize = getSize(props.size ?? 'xl')
    const displayColor = undefined
    props.focusable = props.focusable ?? false
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={displayColor}
            aria-hidden={!title}
            role={title ? 'img' : undefined}
            width={displaySize}
            height={displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Motorbike_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='Motorbike_svg__cls-1'
                d='M32.24 24.37A6.87 6.87 0 0133 23c4.1-5.92 10.48-2.69 10.76-2.54a1 1 0 00.94-1.77 12.32 12.32 0 00-7-1.08v-.08l-2.24-4.12a1 1 0 00-.83-.52h-.8L32 7.67a1 1 0 00-.95-.67H27a1 1 0 000 2h3.34l1.3 3.73h-.24l-2.15.4a1 1 0 00-.37.14l-2.46 1.63c-9 5.06-15.56.49-15.83.29A1 1 0 0010 15H1a1 1 0 000 2h3v3a1 1 0 00.8 1c4.31.86 7.89 1.74 10.72 4l-3.26 2.22A7.93 7.93 0 008 26a8 8 0 105.86 2.57L17 26.45a8.62 8.62 0 011.33 4.45 1 1 0 00.29.72 1 1 0 00.71.29h6.14c3.06 0 4.66-3.45 4.88-4l.45-.91a1 1 0 000-1c-1.14-1.85-5.49-2-6.8-2a1 1 0 000 2 12.33 12.33 0 014.67.77l-.14.27a.93.93 0 00-.05.1s-1.18 2.77-3.06 2.77h-5.19a9.26 9.26 0 00-2.38-5.5c-3.17-3.14-7.09-4.25-11.85-5.23V17h3.7a15.61 15.61 0 008.3 2.27 19.18 19.18 0 009.49-2.66l2.36-1.53 1.89-.35h.08l2.15.1 1.81 3.32a9.82 9.82 0 00-4.37 3.67 8.73 8.73 0 00-1 1.83 1 1 0 00.57 1.3 1.15 1.15 0 00.36.07 1 1 0 00.9-.65zM14 34a6 6 0 11-6-6 5.92 5.92 0 012.41.51l-2.85 1.94a1 1 0 00-.26 1.39 1 1 0 00.83.44 1 1 0 00.56-.18l3.5-2.39A6 6 0 0114 34z'
            />
            <path
                className='Motorbike_svg__cls-1'
                d='M47.23 30.59a7.94 7.94 0 00-8.18-4.52L38 21.76l-2 .48 3 12 2-.48L39.54 28a6 6 0 012.49.32A6 6 0 0145.65 36a6 6 0 11-9.22-6.85 1 1 0 00-1.19-1.61A8 8 0 0040 42a8 8 0 007.54-5.3 7.9 7.9 0 00-.31-6.11z'
            />
            <path
                d='M27.91 13.93A5 5 0 0024 12a5 5 0 00-5 5v.23a17.4 17.4 0 007.4-2.33z'
                fill='#d7153a'
            />
        </svg>
    )
}
