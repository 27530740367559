import { getSize } from '../iconUtils'
import * as React from 'react'
import type { SVGProps } from 'react'
export interface BrandIconCalendarProps {
    title?: string;
    titleId?: string;
    size?: 'lg' | 'xl' | 'xxl' | string | number;
}
export const BrandIconCalendar = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & BrandIconCalendarProps) => {
    const displaySize = getSize(props.size ?? 'xl')
    const displayColor = undefined
    props.focusable = props.focusable ?? false
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={displayColor}
            aria-hidden={!title}
            role={title ? 'img' : undefined}
            width={displaySize}
            height={displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Calendar_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='Calendar_svg__cls-1'
                d='M45 6h-3v2h3a1 1 0 011 1v5H2V9a1 1 0 011-1h5v2.93a1 1 0 002 0V3a1 1 0 00-2 0v3H3a3 3 0 00-3 3v34a3 3 0 003 3h42a3 3 0 003-3V9a3 3 0 00-3-3zm0 38H3a1 1 0 01-1-1V16h44v27a1 1 0 01-1 1z'
            />
            <path
                className='Calendar_svg__cls-1'
                d='M18 11a1 1 0 002 0V3a1 1 0 00-2 0v3h-6v2h6zm10 0a1 1 0 002 0V3a1 1 0 00-2 0v3h-6v2h6zm10 0a1 1 0 002 0V3a1 1 0 00-2 0v3h-6v2h6z'
            />
            <circle className='Calendar_svg__cls-1' cx={8} cy={22} r={1} />
            <circle className='Calendar_svg__cls-1' cx={16} cy={22} r={1} />
            <circle className='Calendar_svg__cls-1' cx={24} cy={22} r={1} />
            <circle className='Calendar_svg__cls-1' cx={32} cy={22} r={1} />
            <circle className='Calendar_svg__cls-1' cx={40} cy={22} r={1} />
            <circle className='Calendar_svg__cls-1' cx={8} cy={30} r={1} />
            <circle className='Calendar_svg__cls-1' cx={16} cy={30} r={1} />
            <circle className='Calendar_svg__cls-1' cx={24} cy={30} r={1} />
            <circle className='Calendar_svg__cls-1' cx={40} cy={30} r={1} />
            <circle className='Calendar_svg__cls-1' cx={8} cy={38} r={1} />
            <circle className='Calendar_svg__cls-1' cx={16} cy={38} r={1} />
            <circle className='Calendar_svg__cls-1' cx={24} cy={38} r={1} />
            <circle className='Calendar_svg__cls-1' cx={32} cy={38} r={1} />
            <circle className='Calendar_svg__cls-1' cx={40} cy={38} r={1} />
            <path d='M37 30a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
        </svg>
    )
}
