import React, { ChangeEvent, ComponentPropsWithoutRef, Ref } from 'react'
import { flags, SelectWrapper } from './Select.styled'
import {
    deprecation,
    forwarded,
    useControllableValue,
    useId,
} from '@snsw-gel/utils'
import { clsFlags, vars } from '@snsw-gel/theming'
import classNames from 'classnames'
import { IconChevronDown } from '@snsw-gel/icons'
import { inputWidthVar, useProvidedFieldProps } from '@snsw-gel/field'
import { WithElementProps } from '@snsw-gel/types'
export interface SelectProps {
    id?: string
    value?: string
    defaultValue?: string
    onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => any
    disabled?: boolean
    hasError?: boolean
    inputWidth?: 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl'
    /** inputRef is deprecated, use ref instead
     * @deprecated
     * */
    inputRef?: Ref<HTMLSelectElement>
    options: Array<{
        value: string
        text: string
    }>
    placeholder?: string
    className?: string
}

interface SelectElementProps
    extends WithElementProps<ComponentPropsWithoutRef<'select'>, SelectProps> {}

export const Select = forwarded(
    (props: SelectElementProps, ref: Ref<HTMLSelectElement>) => {
        const {
            id: outerId,
            name,
            disabled,
            hasError,
            inputWidth,
            inputRef = ref,
            placeholder = 'Select',
            options = [],
            value: propsValue,
            onChange,
            defaultValue,
            className,
            ...rest
        } = useProvidedFieldProps(props)

        const [value, setValue] = useControllableValue(
            propsValue,
            (value, event: ChangeEvent<HTMLSelectElement>) => {
                onChange?.(event)
            },
            defaultValue,
            '',
        )

        const id = useId(outerId)

        deprecation('inputRef' in props, 'props.inputRef')

        const cls = classNames(
            className,
            hasError && clsFlags.error,
            !!value && flags.hasValue,
        )

        const styles = {}
        if (inputWidth) {
            Object.assign(
                styles,
                inputWidthVar.setStyle(vars.layouts.inputWidths[inputWidth]),
            )
        }

        return (
            <SelectWrapper className={cls} style={styles}>
                <select
                    id={id}
                    name={name}
                    value={value}
                    onChange={e => {
                        setValue?.(e.target.value, e)
                    }}
                    disabled={disabled}
                    ref={inputRef}
                    aria-invalid={hasError || undefined}
                    {...rest}
                >
                    {placeholder && <option value=''>{placeholder}</option>}
                    {options.map((option, index) => (
                        <option key={index} value={option.value}>
                            {option.text}
                        </option>
                    ))}
                </select>
                <IconChevronDown />
            </SelectWrapper>
        )
    },
)

// @ts-ignore
Select.displayName = 'Select'
