import { getSize } from '../iconUtils'
import * as React from 'react'
import type { SVGProps } from 'react'
export interface BrandIconPetrolProps {
    title?: string;
    titleId?: string;
    size?: 'lg' | 'xl' | 'xxl' | string | number;
}
export const BrandIconPetrol = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & BrandIconPetrolProps) => {
    const displaySize = getSize(props.size ?? 'xl')
    const displayColor = undefined
    props.focusable = props.focusable ?? false
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 48 48'
            fill={displayColor}
            aria-hidden={!title}
            role={title ? 'img' : undefined}
            width={displaySize}
            height={displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <defs>
                <style>{'.Petrol_svg__cls-1{fill:#002664}'}</style>
            </defs>
            <path
                className='Petrol_svg__cls-1'
                d='M29 25H15a1 1 0 010-2h14a1 1 0 010 2zm-7-5a1 1 0 01-1-1 1 1 0 112 0 1 1 0 01-1 1zm-5 0a1 1 0 01-.92-1.38 1 1 0 01.21-.33 1 1 0 011.42 0 1 1 0 01.21.33A1 1 0 0117 20zm10 0a1 1 0 01-.38-.08 1 1 0 01-.33-.21 1.15 1.15 0 01-.21-.33.94.94 0 010-.76 1 1 0 01.21-.33 1 1 0 011.42 0 1.15 1.15 0 01.21.33.94.94 0 010 .76 1.15 1.15 0 01-.21.33A1 1 0 0127 20zm13 28h-2v-4H6v4H4v-5a1 1 0 011-1h34a1 1 0 011 1z'
            />
            <path d='M27 11a5 5 0 11-5-5 5 5 0 015 5z' fill='#d7153a' />
            <path
                className='Petrol_svg__cls-1'
                d='M41.71 3.29A17.86 17.86 0 0037.45.11a1 1 0 00-.9 1.78 16.15 16.15 0 013.74 2.82C42 6.38 42 10 42 10v6l-3-2.4V7h-2v7a1 1 0 00.38.79L42 18.53V34a1 1 0 01-2 0V21a3 3 0 00-3-3h-2V7a5 5 0 00-5-5H14a5 5 0 00-5 5v33h2V7a3 3 0 013-3h16a3 3 0 013 3v33h2V20h2a1 1 0 011 1v13a3 3 0 006 0V10c0-.18 0-4.44-2.29-6.71z'
            />
        </svg>
    )
}
