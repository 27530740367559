import { getSize, getSystemColor } from '../iconUtils'
import * as React from 'react'
import type { SVGProps } from 'react'
export interface IconSignOutProps {
    title?: string;
    titleId?: string;
    size?: 'sm' | 'md' | string | number;
    color?:
        | 'currentColor'
        | 'primaryBlue'
        | 'secondaryBlue'
        | 'darkGrey'
        | 'white';
}
export const IconSignOut = ({
    title,
    titleId,
    ...props
}: SVGProps<SVGSVGElement> & IconSignOutProps) => {
    const displaySize = getSize(props.size ?? 'sm')
    const displayColor = getSystemColor(props.color)
    props.focusable = props.focusable ?? false
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 16 16'
            fill={displayColor}
            aria-hidden={!title}
            role={title ? 'img' : undefined}
            width={displaySize}
            height={displaySize}
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <path d='M7 16H1a1 1 0 01-1-1V1a1 1 0 011-1h6a1 1 0 011 1v3H6V2H2v12h4v-2h2v3a1 1 0 01-1 1z' />
            <path d='M15.71 7.29l-4-4a1 1 0 00-1.42 1.42L12.59 7H5a1 1 0 000 2h7.59l-2.3 2.29a1 1 0 000 1.42 1 1 0 001.42 0l4-4a1 1 0 000-1.42z' />
        </svg>
    )
}
