import React from 'react'
import PropTypes from 'prop-types'
import StyledSkeletonShape from './SkeletonShape.styled'

export const SkeletonShape = ({
    width = 60,
    height = 60,
    size,
    circle = false,
}) => (
    <StyledSkeletonShape
        data-testid='skeleton-shape'
        height={height}
        width={width}
        size={size}
        circle={circle}
    >
        <div />
    </StyledSkeletonShape>
)

SkeletonShape.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    /** All available Brand and System Icon sizes. */
    size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl', 'xxl']),
    /** Use circle to represent Icons or Avatars */
    circle: PropTypes.bool,
}
